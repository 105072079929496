import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { action, computed, observable } from 'mobx';
import { API } from '../api';

interface resultValue<T> {
  code: number;
  data?: T;
  msg?: string;
}

export default class AuditModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable
  public visible = false;

  @observable
  public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public id = null; // 用户id

  @observable public parent = null;

  @observable public showAuditOpinion = false;

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action public onAuditResultChange = (e) => {
    this.showAuditOpinion = e.target.value === 2;
  };

  @action
  public onShow = (data?: any) => {
    this.id = data?.id;
    this.showAuditOpinion = false;
    this.visible = true;
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    console.log(this.ref.getFieldsValue());
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    const postParams = { ...values };
    request<resultValue<any>>({
      url: API.editUserIdentity,
      method: 'POST',
      data: {
        ...postParams,
        id: this.id,
      },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.parent?.pageStore?.grid?.onQuery();
        this.ref.resetFields();
        this.onClose();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
