import React from 'react';
import { observer } from 'mobx-react';
import { SearchListStructure } from '../../utils/searchListStructure';
import Model from './model';
import Audit from './modal/audit';

const store = new Model();

const Withdrawal = observer(() => {
  const { pageStore, auditStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <Audit store={auditStore} />
    </div>
  );
});

export default Withdrawal;
