import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Button, Tag, Tooltip } from 'antd';
import { action, observable } from 'mobx';
import { request, SearchListModal, RenderByPermission } from '../../utils';
import type { BaseData, IMainSubStructureModel, MainSubStructureModel, NormalProgrammeParams } from '../../utils';
import AuditModel from './modal/auditModel';
import { API } from './api';
import { AuditStatusDict } from '../../utils/dict';

export default class Model {
  constructor() {
    this.pageStore.grid.onQuery();
  }

  @observable
  public total = 0;

  public auditStore = new AuditModel({ parent: this });

  public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        label: '用户ID',
        field: 'userId',
      },
      {
        type: 'input',
        label: '用户昵称',
        field: 'nickName',
      },
      {
        type: 'input',
        label: '手机号',
        field: 'mobile',
      },
    ],
  };

  @action
  public setCollectData = () => {
    this.pageStore.grid.collectData = [
      {
        name: '总量',
        value: this.total || 0,
        color: 'blue',
      },
    ];
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: this.total,
        color: 'blue',
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_14`}>
                  <Button
                    type="link"
                    onClick={() => this.auditStore.onShow(row)}
                    disabled={row.auditStatus !== 0}
                    title={row.auditStatus !== 0 ? '已审核' : ''}
                  >
                    审核
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'userId',
          name: '用户ID',
        },
        {
          key: 'nickName',
          name: '用户',
        },
        {
          key: 'content',
          name: '申请理由',
        },
        {
          key: 'mobile',
          name: '手机号',
        },
        {
          key: 'createTime',
          name: '申请时间',
          formatter: ({ row }) => (row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
        {
          key: 'auditTime',
          name: '处理时间',
          formatter: ({ row }) => (row.auditTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
        {
          key: 'auditStatus',
          name: '处理结果',
          formatter: ({ row }) => {
            const colorMap = {
              0: 'gold',
              1: 'green',
            };
            return row.auditStatus === 2 ? (
              <Tooltip title={row.auditReason}>
                <span style={{ cursor: 'pointer' }}>{`未通过，原因：${row.auditReason}`}</span>
              </Tooltip>
            ) : (
              <Tag color={colorMap[row.auditStatus]}>{AuditStatusDict.find((item) => item.dictValue === row.auditStatus)?.dictName}</Tag>
            );
          },
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const { filterParams, pageNum, pageSize } = params;
        const postParams = {
          ...filterParams,
          pageNum,
          pageSize,
        };

        return request<BaseData<any>>({
          url: API.fetchDistributionList,
          method: 'POST',
          data: postParams,
        });
      },
      callbackAfterQuery: (_this: MainSubStructureModel) => {
        this.total = _this.gridModel.total;
        this.setCollectData();
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
