import { Button, Form, Input, Modal, Space, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Model from './auditModel';
import { AuditOptionsDict } from '../../../utils/dict';

// 新增或编辑需求配置信息
export default observer(({ store }: { store: Model }) => {
  if (!store) {
    return null;
  }
  const { visible, saveLoading, showAuditOpinion, onClose, onSave, setRef, onAuditResultChange } = store;

  return (
    <Modal
      centered
      title="审核"
      onOk={onSave}
      confirmLoading={saveLoading}
      maskClosable={false}
      onCancel={onClose}
      open={visible}
    >
      <div style={{ paddingTop: '16px' }}>
        <Form ref={setRef}>
          <Form.Item
            label="审核结果"
            name="auditStatus"
            rules={[
              {
                required: true,
                message: '请选择审核结果',
              },
            ]}
          >
            <Radio.Group onChange={onAuditResultChange}>
              {AuditOptionsDict.map((item) => (
                <Radio
                  key={item.dictValue}
                  value={item.dictValue}
                >
                  {item.dictName}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          {showAuditOpinion ? (
            <Form.Item
              label="原因"
              name="auditReason"
              rules={[{ required: true }]}
            >
              <Input.TextArea
                maxLength={200}
                placeholder="请填写原因"
                autoSize={{
                  minRows: 4,
                  maxRows: 8,
                }}
                showCount
              />
            </Form.Item>
          ) : null}
        </Form>
      </div>
    </Modal>
  );
});
